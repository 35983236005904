import * as React from "react";
import { styled } from "@mui/material/styles";
import logo from "../../../src/assets/Logo-Entrepay-Fundo-Vazio.png";
import imgFoguete from "../../../src/assets/terms_finishpink(3).png";
import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

require('../tokenRenewal')

const HeaderLogo = styled("img")({
  width: "380px",
  height: "90px",
});

const logoStyled = {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  padding: "40px",
};

function TermsComplete(props) {
  const navigate = useNavigate();

  let { uuid } = useParams();

  const handleDownload = async () => {
    try {
      const response = await axios.get('/Contrato_Geral_2023.pdf', { responseType: 'blob' }); // Substitua o caminho pelo caminho correto do seu arquivo PDF
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Contrato_Geral_2023.pdf'; // Substitua "arquivo.pdf" pelo nome que desejar para o arquivo no download
      link.click();
      URL.revokeObjectURL(url); // Libera o URL Blob após o download
    } catch (error) {
      console.error(error);
    }
  };

  const getTerms = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_NO_TOKEN_URL + "/terms/" + uuid,
        {
          headers: {
            Authorization: props.token,
            'Content-Type': 'application/json',
          },
        }
      );
      // //console.log(response);
      setTerms(response.data); // Atualiza o estado com os dados recebidos

    } catch (error) {
      console.error(error);
      navigate("/signin/");
    }
  };

  React.useEffect(() => {
    getTerms(); // Chama a função para obter os termos
  }, []); // Array vazio faz com que o useEffect execute apenas uma vez


  const [terms, setTerms] = useState(false);

  return (
    <div>
      <div style={logoStyled}></div>
      <div style={{
          maxWidth: "90%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          textAlign: 'center'
      }}>
          <div style={{flex:1}}>
              <img src={imgFoguete} />
          </div>
          <div style={{
            border: "solid 3px #000",
            borderRadius: 20,
            flex: 1,
            padding: "3rem",
            textAlign: "center"
          }}>
            <div>
                <HeaderLogo className="logo" src={logo} />
            </div>
            <div  style={{fontWeight: 500}}>
            <br/>
                <div style={{fontWeight: 600, fontSize: "larger"}}>
                    <p>Que maravilha! <br/>Você deu o primeiro passo rumo<br/>à nossa possível parceria.</p>
                </div>
                <br/>
                <div >
                    <p>Nesse momento estamos realizando uma<br/> breve análise e entraremos em contato por<br/> email, tudo bem?</p>
                </div>
                <br/>
                <div>
                    <p>Confira o contrato Entrepay <a href="#" onClick={handleDownload}>aqui</a>.</p>
                </div>
                <br/>
                <div>
                    <p>Você já pode fechar essa página.</p>
                </div>
          </div>
        </div> 
      </div>

    </div>
  );
}
export default TermsComplete;
