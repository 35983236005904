import React from "react";
import './style.css'
const CardInit = ({ icon, title, description, onClick, actionName, className  }) => {

  return (
    <div
      className={`CardInitContainer ${className}`}
      onClick={onClick}
    >
      <div className="cardIcon flex">
        {icon}
      </div>

      <div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      
      {className !== 'blocked' &&
        (<button>{actionName ? actionName : "Acessar"}</button>)
      }

      {className === 'blocked' &&
        (<button>Já foi enviado.</button>)
      }
    </div>
  );
};

export default CardInit;
