import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import "./FooterButtons.css";

const FooterButtons = ({
  ButtonStyle,
  SendButtonStyle,
  onClickNext,
  handleClickOpen,
  handleClose,
  open,
  onClickFinalize,
  textSend,
  sinalization,
}) => {
  return (
    <footer>
      <div className="footerContent">
        {sinalization && <p>{sinalization}</p>}
        <div className="footerButtons">
          <Button
            sx={{
              ...ButtonStyle,
              textTransform: "none",
              fontSize: "16px",
              "& span:first-letter": {
                // textTransform: "uppercase",
              },
            }}
            variant="contained"
            onClick={onClickNext}
            title="Clique para salvar as alterações e retomar mais tarde."
          >
            <span>Salvar alterações</span>
          </Button>
          <Button
            sx={{
              ...SendButtonStyle,
              textTransform: "none",
              fontSize: "16px",
              "& span:first-letter": {
                // textTransform: "uppercase",
              },
            }}
            variant="contained"
            onClick={handleClickOpen}
            title="Clique para finalizar o envio e enviar para análise."
          >
            <span>Finalizar e Enviar</span>
          </Button>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{ textAlign: "center" }} id="alert-dialog-title">
            {"Confirmar envio"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Clicando em confirmar, você envia {textSend} para a análise,{" "}
              <br />
              <strong>não sendo possível retomá-los</strong>.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{ color: "#e5007e" }} onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              style={{ color: "#e5007e", fontWeight: "bold" }}
              onClick={onClickFinalize}
              autoFocus
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </footer>
  );
};

export default FooterButtons;
