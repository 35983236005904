import * as React from "react";
import { Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

const modalOverlayStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 90,
};

require("../pages/tokenRenewal");

function TermosPrivacidade(props) {
  const { handleCloseTermosPrivacidade, handleOpenTermosPrivacidade, open} = props; 

  return (
    <>
    <div style={modalOverlayStyles}>
      <Grid
      className="Modal"
      container
      justifyContent="center"
      alignItems="center"
      style={{  position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      p: 4,
      zIndex: "99",
      display: "flex",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: 5 }} 
    >
        <Grid item>
          <Card
            sx={{
              marginTop: 10,
              marginBottom: 10,
              // boxShadow: "0 0 1em #DCDCDC",
              padding: 3,
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ padding: 4 }}
            >
              Política de Privacidade
            </Typography>
            <CardContent
              sx={{
                maxWidth: 700,
                overflow: "auto",
                maxHeight: 500,
                display: "flex",
                paddingRight: 4,
                paddingLeft: 6,
                color: "black",
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ height: "45vh" }}
              >
                <div className="page" title="Page 3">
                  <div className="layoutArea">
                    <div className="column">
                      <p>
                        <span>
                          Bem-vindo(a)! Obrigado por utilizar a Entrepay!{" "}
                        </span>
                      </p>
                      <p>
                        A ENTREPAY (Entrepay Instituição De Pagamentos S.A. -
                        17.887.874/0001-05), com sede à Rua Iguatemi, nº 192 –
                        25º Andar – Conjuntos 251 a 254, Bairro Itaim Bibi, em
                        São Paulo/SP, está comprometida em resguardar sua
                        privacidade e proteger seus dados pessoais. Deste modo,
                        esta Política de Privacidade (“Política”) descreve os
                        dados que coletamos, como eles são usados, armazenados e
                        compartilhados. Assim como, os seus direitos no que diz
                        respeito a eles.
                      </p>
                      <p>
                        Para que você possa utilizar os nossos serviços, é
                        preciso aceitar, de forma livre e expressa, esta
                        Política de Privacidade.
                      </p>
                      <p>
                        A utilização dos nossos serviços implica no
                        consentimento inequívoco para coleta e tratamento de
                        dados. Além disso, você declara que leu, entendeu e
                        consentiu integralmente com esta Política de
                        Privacidade, concordando que a ENTREPAY tenha acesso a
                        tais informações.
                      </p>
                      <p>
                        Após a leitura desta Política, se você tiver dúvidas,
                        reclamações ou quiser se comunicar com a ENTREPAY sobre
                        esse assunto, você pode entrar em contato conosco pelo
                        e-mail: dpo@entrepay.com.br.
                      </p>
                      <span>1. DEFINIÇÕES </span>
                      <p>
                        1.1. Para auxiliar na leitura desta Política, serão
                        adotadas as seguintes definições, grafadas com a inicial
                        maiúscula, utilizadas no singular ou no plural:
                      </p>

                      <p>Agentes de Tratamento: O Controlador e o Operador.</p>

                      <p>
                        Anonimização: Utilização de meios técnicos razoáveis e
                        disponíveis no momento do tratamento, por meio dos quais
                        um dado perde a possibilidade de associação, direta ou
                        indireta, a um indivíduo.
                      </p>

                      <p>
                        Autoridade Nacional: Órgão da administração pública
                        responsável por zelar, implementar e fiscalizar o
                        cumprimento da Lei nº 13.709/2018 (“LGPD”) em todo o
                        território nacional.
                      </p>

                      <p>
                        Consentimento: Manifestação livre, informada e
                        inequívoca pela qual o titular concorda com o tratamento
                        de seus dados pessoais para uma finalidade determinada.
                      </p>

                      <p>
                        Controlador: Pessoa natural ou jurídica, de direito
                        público ou privado, a quem competem as decisões
                        referentes ao tratamento de dados pessoais.
                      </p>

                      <p>
                        Dado pessoal: Informação relacionada a pessoa natural
                        identificada ou identificável. Podem ser citados como
                        exemplos: nome, CPF, RG, endereço, telefone celular e
                        e-mail.
                      </p>

                      <p>
                        Dado pessoal sensível: Dado pessoal sobre origem racial
                        ou étnica, convicção religiosa, opinião política,
                        filiação a sindicato ou a organização de caráter
                        religioso, filosófico ou político, dado referente à
                        saúde ou à vida sexual, dado genético ou biométrico,
                        quando vinculado a uma pessoa natural.
                      </p>

                      <p>
                        Encarregado: Pessoa indicada pelo controlador e operador
                        para atuar como canal de comunicação entre o
                        controlador, os titulares dos dados e a Autoridade
                        Nacional de Proteção de Dados (ANPD).
                      </p>

                      <p>
                        Operador: Pessoa natural ou jurídica, de direito público
                        ou privado, que realiza o tratamento de dados pessoais
                        em nome do controlador.
                      </p>

                      <p>
                        Plataforma: Sistema disponibilizado pela ENTREPAY para
                        cadastro e homologação de Usuários. Auxiliando no
                        credenciamento de estabelecimentos e servindo, também,
                        como meio oficial de comunicação e relacionamento
                        operacional entre a ENTREPAY e o Usuário;{" "}
                      </p>

                      <p>
                        Titular: Pessoa natural a quem se referem os dados
                        pessoais que são objeto de tratamento.
                      </p>

                      <p>
                        Tratamento: Toda operação realizada com dados pessoais,
                        como as que se referem a coleta, produção, recepção,
                        classificação, utilização, acesso, reprodução,
                        transmissão, distribuição, processamento, arquivamento,
                        armazenamento, eliminação, avaliação ou controle da
                        informação, modificação, comunicação, transferência,
                        difusão ou extração.
                      </p>

                      <p>
                        Usuário(s)/Você: Toda pessoa natural ou jurídica, de
                        direito público ou privado que utilizarem os serviços da
                        ENTREPAY;{" "}
                      </p>

                      <p>
                        Uso compartilhado de dados: Comunicação, difusão,
                        transferência internacional, interconexão de dados
                        pessoais ou tratamento compartilhado de bancos de dados
                        pessoais por órgãos e entidades públicos no cumprimento
                        de suas competências legais, ou entre esses e entes
                        privados, reciprocamente, com autorização específica,
                        para uma ou mais modalidades de tratamento permitidas
                        por esses entes públicos, ou entre entes privados.
                      </p>

                      <span>2. COLETA DE DADOS</span>

                      <p>
                        2.1. A ENTREPAY poderá coletar dados sobre você em
                        diferentes situações. Em geral, é possível que você nos
                        forneça dados diretamente através do cadastro e
                        homologação em nossa Plataforma, entretanto, também
                        podemos coletar seus dados de maneira automática por
                        meio de cookies, por exemplo.
                      </p>

                      <p>
                        2.2. Nós também poderemos coletar dados em fontes
                        publicamente acessíveis ou junto a terceiros autorizados
                        e contratados pela ENTREPAY sujeitos a obrigações de
                        confidencialidade, como por exemplo instituições
                        financeiras (inclusive o Sistema de Informações de
                        Crédito do Banco Central do Brasil), bureaus de crédito
                        e quaisquer sistemas de pesquisa e análise, ou serviços
                        e/ou repositórios de dados públicos ou privados aos
                        quais a ENTREPAY tenha acesso.{" "}
                      </p>

                      <p>
                        2.3. O tratamento dos dados pessoais tratados pela
                        ENTREPAY respeita os princípios estabelecidos no artigo
                        6º da LGPD, especialmente o princípio da necessidade,
                        que estabelece a limitação do tratamento ao mínimo
                        necessário para a realização das finalidades previstas,
                        de forma proporcional e não excessiva. Logo, tratamos os
                        dados fornecidos por você com a exclusiva finalidade de
                        analisar potencial oportunidade de negócio, bem como
                        para: (i) Cumprimento de obrigação legal ou regulatória;
                        (ii) Realizar prevenção a fraudes; e (iii) Quando
                        necessário para atender aos interesses legítimos do
                        controlador ou de terceiros (exceto no caso de
                        prevalecerem direitos e liberdades fundamentais do
                        titular que exijam a proteção dos dados pessoais).
                      </p>

                      <span>
                        3. INFORMAÇÕES QUE RECEBEMOS, TRATAMOS E ARMAZENAMOS
                      </span>

                      <p>
                        3.1. A ENTREPAY armazena seus dados pessoais, assim como
                        dados de utilização, de relacionamento e perfil para
                        criar estatísticas e gráficos, utilizados pela ENTREPAY
                        para aperfeiçoar e desenvolver uma melhor experiência
                        para os Usuários no uso da sua Plataforma.
                      </p>

                      <p>
                        3.2. Para criação de uma conta de acesso à Plataforma da
                        ENTREPAY Esses são os dados que você nos fornece, e nós
                        coletamos durante o momento do seu cadastro:
                      </p>

                      <p>
                        (i) Razão social;
                        <br />
                        (ii) Número do Cadastro Nacional de Pessoa Jurídica -
                        CNPJ;
                        <br />
                        (iii) Logradouro;
                        <br />
                        (iv) Representantes legais;
                        <br />
                        (v) Contrato Social/Estatuto Social; e<br />
                        (vi) Endereço de e-mail.
                      </p>
                      <p>
                        3.3. A partir da criação da conta de acesso, Você será
                        considerado Usuário da Plataforma da ENTREPAY e deverá
                        fornecer, quando necessário, os dados relacionados a sua
                        representação legal, mais especificamente:{" "}
                      </p>

                      <p>
                        (i) Nome completo; <br />
                        (ii) Documentos de identificação;
                        <br />
                        (iii) Nacionalidade;
                        <br />
                        (iv) Profissão;
                        <br />
                        (v) Estado civil;
                        <br />
                        (vi) Endereço de e-mail <br />
                        (vii) Telefone de contato; e<br />
                        (viii) Gênero.
                      </p>

                      <p>
                        3.4. A ENTREPAY, também, poderá solicitar informações
                        adicionais ao Usuários, incluindo, mas não se limitando,
                        a: (i) Informações Contábeis;
                        <br />
                        (ii) Informações Financeiras;
                        <br />
                        (iii) Informações de Regularidade Fiscal;
                        <br />
                        (iv) Projeção de Faturamento; e<br />
                        (v) Volume de Vendas.
                      </p>

                      <p>
                        3.5. Adicionalmente, a ENTREPAY poderá coletar, por meio
                        de tecnologias automatizadas, outros dados e informações
                        do Usuário durante as interações na Plataforma, como por
                        exemplo dados e registros (logs) de acesso, cookies,
                        número de IP, local, data e hora. Estas informações
                        poderão ser coletadas automaticamente, independentemente
                        de ação do Usuário.
                      </p>
                      <span>4. USO E ARMAZENAMENTO DA INFORMAÇÃO</span>

                      <p>
                        4.1.A ENTREPAY utiliza os dados fornecidos para realizar
                        a adesão do Usuário ao sistema ENTREPAY. Além disso, a
                        ENTREPAY poderá utilizar os dados fornecidos para a
                        criação de usuários, habilitação ou desabilitação de
                        funcionalidades, atendimento ao cliente, acompanhamento
                        de atividades do Usuário, comunicações de marketing,
                        condução de auditorias e, por fim, para cumprir
                        obrigações legais ou regulatórias.
                      </p>

                      <p>
                        4.1.1. Nos casos em que for necessário realizar o
                        tratamento de dados pessoais sensíveis, esse tratamento
                        será feito de acordo com os requisitos da legislação
                        aplicável.{" "}
                      </p>

                      <p>
                        4.2. A ENTREPAY poderá, ainda, transmitir os seus Dados
                        a eventuais parceiros comerciais/prestadores de serviços
                        que nos auxiliam em diversos processos diários, bem como
                        poderá compartilhar os seus dados com eventuais empresas
                        afiliadas (empresas sob mesmo controle e propriedade)
                        que fazem parte do fluxo operacional.{" "}
                      </p>

                      <p>
                        4.3. A ENTREPAY se reserva o direito de reter os seus
                        Dados pelo período necessário para que você possa
                        acessar a nossa Plataforma e utilizar os nossos Serviços
                        ou, ainda, até que os Dados deixem de ser necessários ou
                        pertinentes ao alcance da finalidade específica almejada
                        pelas Partes.{" "}
                      </p>

                      <p>
                        4.4. Relatórios estatísticos sobre o acesso, utilização
                        da Plataforma e tendências de mercado, que não revelem a
                        identidade dos Usuários, poderão também ser
                        compartilhados com afiliados, subcontratados ou
                        parceiros comerciais da ENTREPAY.
                      </p>

                      <span>5. OBRIGAÇÕES E GARANTIAS</span>

                      <p>
                        5.1. Ao preencher o cadastro para acesso à Plataforma, o
                        interessado declara-se o único responsável civil e
                        criminalmente pela veracidade, certeza, suficiência e
                        consistência das informações prestadas, bem como, por
                        eventual divergência entre os dados informados e os
                        dados reais e/ou oficiais, incluindo quaisquer perdas
                        relacionadas e incorridas pela ENTREPAY.
                      </p>

                      <p>
                        5.2. A ENTREPAY se reserva ao direito de checar a
                        veracidade das informações fornecidas pelo interessado
                        na Plataforma, a qualquer momento, podendo inclusive
                        solicitar informações e documentos adicionais, caso
                        entenda necessário para a análise.
                      </p>

                      <p>
                        5.3. Ao aceitar esta Política de Privacidade, Você está
                        ciente que a abertura da conta de acesso e o processo de
                        homologação estão condicionados à análise cadastral, nos
                        termos definidos única e exclusivamente pela ENTREPAY,
                        podendo ser recusada, independentemente de exposição de
                        motivos.
                      </p>

                      <p>
                        5.3.1. Fica delimitado que a ENTREPAY poderá alterar as
                        condições de admissibilidade à Plataforma a qualquer
                        momento, e ao seu exclusivo critério.
                      </p>

                      <p>
                        5.3.2. O interessado e postulante a subadquirente deverá
                        estar em plena conformidade com as regras e
                        regulamentações do Banco Central do Brasil, das
                        Bandeiras e Emissores integrantes do Arranjo de
                        Pagamento, bem como com a legislação vigente atinente à
                        matéria e se compromete a celebrar, junto às Bandeiras,
                        os respectivos contratos de participação nos arranjos de
                        pagamento, conforme determinação destas.
                      </p>

                      <p>
                        5.4. O titular dos Dados Pessoais tem direito a obter da
                        ENTREPAY, em relação aos dados eventualmente tratados, a
                        qualquer momento e mediante requisição:
                      </p>

                      <p>
                        (i) Confirmação da existência de tratamento;
                        <br />
                        (ii) Acesso aos dados;
                        <br />
                        (iii) Correção de dados incompletos, inexatos ou
                        desatualizados;
                        <br />
                        (iv) Anonimização, bloqueio ou eliminação de dados
                        desnecessários, excessivos ou tratados em
                        desconformidade com o disposto nesta Lei;
                        <br />
                        (v) Portabilidade dos dados a outro fornecedor de
                        serviço ou produto, mediante requisição expressa e
                        observados os segredos comercial e industrial, de acordo
                        com a regulamentação do órgão controlador;
                        <br />
                        (vi) Portabilidade dos dados a outro fornecedor de
                        serviço ou produto, mediante requisição expressa, de
                        acordo com a regulamentação da autoridade nacional,
                        observados os segredos comercial e industrial;
                        <br />
                        (vii) Eliminação dos dados pessoais tratados com o
                        consentimento do titular, exceto nas hipóteses previstas
                        no art. 16 desta Lei;
                        <br />
                        (viii) Informação das entidades públicas e privadas com
                        as quais o controlador realizou uso compartilhado de
                        dados;
                        <br />
                        (ix) Informação sobre a possibilidade de não fornecer
                        consentimento e sobre as consequências da negativa; e
                        <br />
                        (x) Revogação do consentimento.
                      </p>

                      <p>
                        5.5. Você poderá, a qualquer momento, alterar e/ou
                        editar os seus dados cadastrais, entrando em contato com
                        a ENTREPAY por meio do envio de e-mail ou pelos canais
                        de atendimento ao consumidor e compliance.{" "}
                      </p>

                      <p>
                        5.6. Você é responsável pela senha cadastrada, sendo
                        esta pessoal e intransferível, ficando a sua guarda sob
                        a sua responsabilidade. Ademais, o uso e a proteção dos
                        dados de acesso à sua conta são de sua inteira
                        responsabilidade, por isso recomendamos que você guarde
                        esses dados em local seguro e não os forneça a
                        terceiros, de forma que a ENTREPAY não se
                        responsabilizará pela utilização inadequada do seu
                        perfil e da plataforma por terceiros que eventualmente
                        venham a acessar a sua conta com o seu login e senha.
                      </p>

                      <span>6. PRIVACIDADE E SEGURANÇA DAS INFORMAÇÕES</span>

                      <p>
                        6.1. A ENTREPAY se compromete com a privacidade dos
                        dados pessoais fornecidos pelos Usuários para a
                        utilização da Plataforma.
                      </p>

                      <p>
                        6.2. Para a segurança das informações, a ENTREPAY
                        informa que o conteúdo da Plataforma poderá ser
                        modificado a qualquer momento, sem aviso prévio aos
                        Usuários, para que esteja atualizado e livre de
                        intercorrências.
                      </p>

                      <p>
                        6.3. A ENTREPAY não garante a disponibilidade ao acesso
                        da Plataforma integralmente, e não se responsabilizará
                        por eventual indisponibilidade da Plataforma, em
                        decorrência de falhas no sistema. Assim, a ENTREPAY
                        declara desde já que se empenha em oferecer o melhor
                        serviço possível, mas não garante a qualidade
                        satisfatória, adequação para uma determinada finalidade
                        ou não infração de terceiros. A ENTREPAY não garante que
                        os serviços de terceiros estejam livres de malware, e
                        não assume a responsabilidade por plataformas de
                        terceiros, inclusive, mas sem limitar à hiperlinks por
                        terceiros fornecidos ou pelo conteúdo fornecido pelo
                        Usuário.{" "}
                      </p>

                      <span>7. DECLARAÇÕES DOS USUÁRIOS</span>

                      <p>
                        7.1. Ao aceitar a presente Política de Privacidade e
                        Proteção de Dados, o Usuário, declara:
                      </p>

                      <p>
                        a) Reconhecer que, como protocolo de segurança e
                        prevenção a fraudes, e em cumprimento às
                        obrigações/disposições legais e/ou regulatórias, a
                        ENTREPAY poderá utilizar os dados coletados para
                        realizar validações e consultas a fontes de informações
                        em entidades de caráter público ou privado;
                      </p>

                      <p>
                        b) Reconhecer que foi informado que a ENTREPAY irá
                        consultar bases públicas e por meio de terceiros, para
                        checagem de informações e análise de crédito.
                      </p>

                      <p>
                        c) Reconhecer que foi informado que, caso seja
                        cadastrado na Plataforma e com isso, prossiga no
                        processo de seu credenciamento junto à ENTREPAY, esta
                        poderá, para seguir com o cadastramento, coletar e
                        tratar dados pessoais de membros do Usuário, tais como
                        documentos de Identidade e CPF do(s) proprietário(s)
                        e/ou sócio(s) da Empresa, para cumprimento de obrigação
                        legal e regulatória a que a ENTREPAY está submetida,
                        observados as leis e regulamentações que regem a
                        privacidade e a proteção de dados pessoais, e nos termos
                        da Política de Privacidade ENTREPAY, parte integrante
                        deste Termos.
                      </p>

                      <p>
                        7.2. O Usuário concorda em compensar e isentar a
                        ENTREPAY e seus administradores, diretores, funcionários
                        e agentes, de e por todas e quaisquer reclamações,
                        perdas, prejuízos e encargos contratuais, judiciais ou
                        administrativos, decorrentes ou relacionados com (i) a
                        infração ou violação de qualquer uma das presentes
                        condições por parte do Usuário; (ii) a violação dos
                        direitos de qualquer terceiro. A ENTREPAY poderá retirar
                        o acesso do Usuário ao seu exclusivo critério, sem
                        justificativa.{" "}
                      </p>

                      <p>
                        7.3. O Usuário concorda com o compartilhamento de seus
                        dados entre as empresas do mesmo grupo econômico da
                        ENTREPAY, visando facilitar a experiência dos Usuários
                        ao utilizar os sites, aplicativos, e serviços
                        oferecidos.
                      </p>

                      <span>8. VIGÊNCIA </span>

                      <p>
                        8.1. O período de vigência deste Termo de Privacidade
                        começará na data em que o Usuário declara estar de
                        acordo e continuará em vigor até que eventual relação
                        com a ENTREPAY seja encerrada em qualquer hipótese.
                      </p>

                      <span>9. NOTIFICAÇÕES</span>

                      <p>
                        9.1 Todas as notificações, intimações, ofícios ou
                        qualquer outra forma oficial de cientificação da
                        ENTREPAY deverão ocorrer em seu endereço de sede,
                        descrito no preâmbulo do presente Termos de Privacidade.
                      </p>

                      <span>10. DISPOSIÇÕES FINAIS</span>

                      <p>
                        10.1. Os Termos de Privacidade poderão sofrer alterações
                        periódicas, seja por questões legais ou estratégicas
                        exclusivas da ENTREPAY. O Usuário sempre será notificada
                        pela ENTREPAY a cada uma nova alteração de seus Termos
                        de Privacidade e Uso, sendo o seu aceite imperioso para
                        a continuidade da fruição da Plataforma – Está política
                        foi atualizada em 10 de agosto de 2023.
                      </p>

                      <p>
                        10.2. Toda informação que a ENTREPAY coleta será mantida
                        em segurança e sob sigilo, com a melhor técnica
                        disponível no momento, para impedir que sejam
                        transferidas, reveladas, divulgadas ou utilizadas, sem
                        autorização, a qualquer terceiro estranho.
                      </p>

                      <p>
                        10.3. O Usuário declara que avaliou todos os termos e
                        condições do presente instrumento, e que aceita e
                        concorda com todas as disposições aqui contidas.
                      </p>

                      <p>
                        10.4. Esta Política de Privacidade se sujeita às leis da
                        República Federativa do Brasil.
                      </p>
                    </div>
                  </div>
                </div>
              </Typography>
            </CardContent>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                onClick={handleCloseTermosPrivacidade}
                sx={{
                  padding: "16px 16px",
                  lineHeight: 1,
                  marginTop: "5%",
                  backgroundColor: "#e5007e",
                  textTransform: "none",
                  fontWeight: 500,
                }}
              >
                Fechar
              </Button>
            </div>
          </Card>
        </Grid>
      </Grid>
      </div>
      {!open && (
  <Button onClick={handleCloseTermosPrivacidade} sx={{ color: "#0085CE" }}>
    Termos de Privacidade
  </Button>
)}

    </>
  );
}

export default TermosPrivacidade;
