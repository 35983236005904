import React from "react";
import { toast } from "react-toastify";
import axios from "axios";

const SaveQuestions = async (
  answers,
  tempRadioAnswers,
  analysis,
  props,
  navigate,
  setLoading
) => {
  const filledAnswers = Object.keys(answers).filter(
    (key) => answers[key].questionAnswer !== ""
  );
  let mergedAnswers = { ...answers };
  const nonNullAnswers = filledAnswers.filter(
    (key) => mergedAnswers[key].id !== null
  );

  if (nonNullAnswers.length === 0) {
    return toast.error("Por favor, preencha o formulário.");
  }

  setLoading(true);
  try {
    let mergedAnswers = { ...answers };
    for (const key in tempRadioAnswers) {
      mergedAnswers[key] = {
        id: tempRadioAnswers[key].id,
        questionAnswer: tempRadioAnswers[key].answer,
        index: tempRadioAnswers[key].id,
      };
    }

    const formattedAnswers = nonNullAnswers.map((key) => ({
      id: mergedAnswers[key].id,
      questionAnswer: mergedAnswers[key].questionAnswer,
      index: mergedAnswers[key].index,
    }));
    await axios.post(
      process.env.REACT_APP_API_URL + "/form/update/" + analysis?.id,
      formattedAnswers,
      {
        headers: {
          Authorization: props.token,
          "Content-Type": "application/json",
        },
      }
    );

    toast.success("Formulário salvo com sucesso.");
    navigate("/homeregistration/");
  } catch (error) {
    console.error(error);
    toast.error("Erro ao salvar formulário.");
  } finally {
    setLoading(false);
  }
};

export default SaveQuestions;
