export const documentosVAR = [
  { name: "Cartão CNPJ da empresa", id: "cartaoCnpj" },
  { name: "Contrato Social atualizado da empresa", id: "contract", description:"Documento obrigatório.*" },
  { name: "Comprovante de endereço da empresa ", id: "varProof", description:"Documento obrigatório.*" },
  { name: "Balanço Financeiro e DRE dos 2 anos anteriores ", id: "financialBalance", description:"Documento obrigatório.*" },
  { name: "Caso deseje enviar outros arquivos que não estejam listados, envie aqui:", id: "others" },
  
  ];
  
  export const sociosVAR = {
  cpf11: [
  { name: "Documento de identidade com foto ", id: "RG", description:"Documento obrigatório.*" },
  { name: "Documento CPF", id: "CPF", description:"Documento obrigatório.*" },
  { name: "Comprovante de Residência do sócio", id: "proofResidencePartner", description:"Documento obrigatório.*" },
  { name: "Declaração do Imposto de renda dos 2 anos anteriores", id: "tax", description:"Documento obrigatório.*" }
  ],
  cpfMais11: [
    { name: "Documento de identidade com foto ", id: "RG" , description:"Documento obrigatório.*"},
    { name: "Documento CPF ", id: "CPF", description:"Documento obrigatório.*" },
    { name: "Comprovante de Residência do sócio ", id: "proofResidencePartner", description:"Documento obrigatório.*" },
    { name: "Declaração do Imposto de renda dos 2 anos anteriores ", id: "tax", description:"Documento obrigatório.*" }
  ]
  };
  
  export const documentosPSP = [
  { name: "Cartão CNPJ da empresa", id: "cartaoCnpj", description:"Documento obrigatório.*" },
  { name: "Contrato Social atualizado da empresa ", id: "contract", description:"Documento obrigatório.*" },
  { name: "Balanço Financeiro e DRE dos 2 anos anteriores ", id: "financialBalance", description:"Documento obrigatório.*" },
  { name: "Comprovante de endereço da empresa ", id: "proofResidence", description:"Documento obrigatório.*" }
  ];
  
  export const sociosPSP = {
  cpf11: [
  { name: "Documento de identidade com foto", id: "RG", description:"Documento obrigatório.*" },
  { name: "Documento CPF ", id: "CPF", description:"Documento obrigatório.*" },
  { name: "Declaração do Imposto de renda dos 2 anos anteriores ", id: "tax" , description:"Documento obrigatório.*"},
  { name: "Comprovante de Residência ", id: "proofResidencePartner", description:"Documento obrigatório.*" },
  ],
  cpfMais11: [
    { name: "Documento de identidade com foto ", id: "RG", description:"Documento obrigatório.*" },
    { name: "Documento CPF ", id: "CPF", description:"Documento obrigatório.*" },
    { name: "Declaração do Imposto de renda dos 2 anos anteriores ", id: "tax", description:"Documento obrigatório.*" },
    { name: "Comprovante de Residência ", id: "proofResidencePartner", description:"Documento obrigatório.*" },
  ]
  };
  
  export const compliancePSP = [
  { name: "Evidência da 1ª página do contrato entre a Subadquirente e a Adquirente (pág. onde constam as informações de cadastro)", id: "evidencia_sub_adq" },
  { name: "Cópia do contrato com a registradora  ", id: "contrato_registradora", description:"Documento obrigatório.*" },
  { name: "Política PLD/FT ", id: "pld_ft", description:"Documento obrigatório.*" },
  { name: "Currículo do Profissional de Compliance/PLD", id: "curriculo_compliance", description:"Documento obrigatório.*" },
  { name: "Certificado do treinamento de PLD ", id: "treinamento_pld_compliance", description:"Documento obrigatório.*" },
  { name: "Organograma funcional ", id: "organograma", description:"Documento obrigatório.*" },
  { name: "Treinamento de PLD/FT ", id: "treinamento_pld_ft", description:"Documento obrigatório.*" },
  { name: "Contrato de prestação de serviços", id: "contrato_prestacao" }, 
  { name: "Avaliação KYP/KYS", id: "kyp_kys" },
  { name: "Plataformas/sistemas utilizados para análise de KYC ", id: "contrato_plataformas", description:"Documento obrigatório.*" },
  { name: "Políticas de KYC, KYP, KYE, KYS ", id: "politicas_kyc" , description:"Documento obrigatório.*"},
  { name: "Sistema Antifraude utilizado para monitoramento das transações", id: "sistema_antifraude", description:"Documento obrigatório.*" },
  { name: "Manual de PLD/FTP e prevenção à fraude", id: "prevencao_fraude" },
  { name: "Evidência da equipe e dos procedimentos destinados a orientar os Estabelecimentos na tratativa de disputas (chargeback)", id: "evidencia_chargeback" },
  { name: "Evidência de acompanhamento dos indicadores", id: "indicadores" },
  { name: "Certificação PCI-DSS ", id: "certificacao_pci", description:"Documento obrigatório.*" },
  { name: "Caso deseje enviar outros arquivos que não estejam listados, envie aqui:", id: "others" },
  
  
  ]

  export const requiredDocumentsVAR = [
     "RG", "CPF", "tax", "proofResidencePartner", "varProof", "contract", "financialBalance"
  ];
  
  export const requiredDocumentsPSP = [
    "RG", "CPF", "tax", "proofResidencePartner", "proofResidence", "cartaoCnpj", "contract", 
    "financialBalance", "certificacao_pci", "politicas_kyc", "pld_ft", "treinamento_pld_compliance", 
    "curriculo_compliance", "organograma", "contrato_registradora", "sistema_antifraude", "contrato_plataformas"
  ];