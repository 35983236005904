import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    pt: {
      translation: {
        cpfOrCnpjLegal1: "CPF/CNPJ - Representante Legal 1*",
        legal1: "Nome completo - Representante Legal 1*",
        occupationLegal1: "Cargo - Representante Legal 1*",
        emailLegal1: "E-mail - Representante Legal 1*",
        cpfOrCnpjLegal2: "CPF/CNPJ - Representante Legal 2",
        legal2: "Nome completo - Representante Legal 2",
        occupationLegal2: "Cargo - Representante Legal 2",
        emailLegal2: "E-mail - Representante Legal 2",
        accountHolderLegalOrNaturalPerson: "O titular da conta é: Pessoa Física ou Pessoa Jurídica?*",
        accountHolder: "Titular da Conta*",
        cpfOrCnpjAccountHolder: "CPF/CNPJ*",
        bank: "Banco*",
        branch: "Agência*",
        account: "Conta*",
        pix: "Pix*",
      },
    },
};

i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  lng: "pt",

  resources,
});

export default i18n;
