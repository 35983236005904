import axios from "axios";
import TermosPrivacidade from "./TermosPrivacidade";
import { useState } from "react";

function Footer() {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <footer
        style={{
          position: "relative",
          left: 0,
          bottom: 0,
          width: "100%",
          textAlign: "center",
          backgroundColor: "#252525",
          borderTop: "0.1px solid rgb(74 74 74)",
          boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.3)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.5rem 1rem",
        }}
      >
        <p className="entreRights">Entrepay © 2024. Todos os direitos reservados</p>
        <div className="footer-link">
          <a
            style={{ marginInline: 20 }}
            href="https://entrepay.com.br/contrato/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contrato Geral Entrepay
          </a>
          <a href="#" onClick={handleOpenModal}>
            Termos de privacidade
          </a>
        </div>
      </footer>

      {openModal && (
        <TermosPrivacidade
          open={openModal}
          handleCloseTermosPrivacidade={handleCloseModal}
        />
      )}
    </>
  );
}

export default Footer;
