export const questionsLegalForm = [
  {
    title: "Representante Legal 1*",
    fields: [
      { id: "cpfOrCnpjLegal1", label: "CPF/CNPJ*", type: "text",placeholder:'XXX.XXX.XXX-XX / XX.XXX.XXX/XXXX-XX' },
      { id: "legal1", label: "Nome completo*", type: "text" },
      { id: "occupationLegal1", label: "Cargo*", type: "text" },
      { id: "emailLegal1", label: "E-mail*", type: "email" },
    ],
  },
  {
    title: "Representante Legal 2",
    fields: [
      { id: "cpfOrCnpjLegal2", label: "CPF/CNPJ", type: "text", placeholder:'XXX.XXX.XXX-XX / XX.XXX.XXX/XXXX-XX' },
      { id: "legal2", label: "Nome completo", type: "text" },
      { id: "occupationLegal2", label: "Cargo", type: "text" },
      { id: "emailLegal2", label: "E-mail", type: "email" },
    ],
  },
  {
    title: "Dados Bancários*",
    fields: [
      {
        id: "accountHolderLegalOrNaturalPerson",
        label: "O titular da conta é: Pessoa Física ou Pessoa Jurídica?*",
        type: "select",
        options: [
          { value: "física", label: "Pessoa Física" },
          { value: "jurídica", label: "Pessoa Jurídica" },
        ],
      },
      { id: "accountHolder", label: "Titular da Conta*", type: "text" },
      { id: "cpfOrCnpjAccountHolder", label: "CPF/CNPJ*", type: "text", placeholder:'XXX.XXX.XXX-XX / XX.XXX.XXX/XXXX-XX' },
      { id: "bank", label: "Banco*", type: "text" },
      { id: "branch", label: "Agência*", type: "text" },
      { id: "account", label: "Conta*", type: "text" },
      { id: "pix", label: "Pix*", type: "text" },
    ],
  },
];
