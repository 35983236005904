import React, { createContext, useState, useContext, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [errorMessages, setErrorMessages] = useState([]);
  const [openError, setOpenError] = useState([]);

  useEffect(() => {
    // Set a timeout to close all open Snackbars after 1 minute
    const timers = openError.map((isOpen, index) => {
      if (isOpen) {
        return setTimeout(() => handleClose(index), 60000); // 60 seconds
      }
      return null;
    });

    // Cleanup the timers when the component unmounts or `openError` changes
    return () => {
      timers.forEach(timer => timer && clearTimeout(timer));
    };
  }, [openError]);

  const handleClose = (index) => {
    setOpenError((prev) => prev.map((open, i) => (i === index ? false : open)));
  };

  return (
    <ErrorContext.Provider value={{ errorMessages, setErrorMessages, openError, setOpenError }}>
      {children}
      {errorMessages.map((message, index) => (
        <Snackbar
          key={index}
          open={openError[index] || false}
          onClose={() => handleClose(index)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          sx={{ marginBottom: `${index * 60}px` }}
        >
          <Alert
            onClose={() => handleClose(index)}
            severity="error"
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      ))}
    </ErrorContext.Provider>
  );
};

export const useErrorContext = () => {
  return useContext(ErrorContext);
};
