export const formatCPFOrCNPJ = (value) => {
  value = value.replace(/\D/g, "");

  if (value.length > 14) {
    value = value.substring(0, 14);
  }

  if (value.length <= 11) {
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else if (value.length === 14) {
    value = value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      "$1.$2.$3/$4-$5"
    );
  }

  return value;
};

export const formatTelefone = (telefone) => {
  let numericTelefone = telefone.replace(/\D/g, "");

  if (numericTelefone.length > 11) {
    numericTelefone = numericTelefone.slice(0, 11);
  }

  const formattedTelefone = numericTelefone.length === 11
    ? numericTelefone.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1)$2-$3")
    : numericTelefone.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1)$2-$3");

  return formattedTelefone;
};



export const formatCnpj = (cnpj) => {
  let numericCnpj = cnpj.replace(/\D/g, "");

  if (numericCnpj.length > 14) {
    numericCnpj = numericCnpj.slice(0, 14);
  }

  const formattedCnpj = numericCnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5"
  );

  return formattedCnpj;
};
export const formatDate = (data) => {
  let numericData = data.replace(/\D/g, "");

  if (numericData.length > 8) {
    numericData = numericData.substring(0, 8);
  }

  const formattedData = numericData.replace(
    /^(\d{2})(\d{2})(\d{4})$/,
    "$1/$2/$3"
  );

  return formattedData;
};

export const formatAndValidateCep = (cep) => {
  let numericCep = cep.replace(/\D/g, "");

  if (numericCep.length > 8) {
    numericCep = numericCep.substring(0, 8);
  }

  const formattedCep = numericCep.replace(/^(\d{5})(\d{3})$/, "$1-$2");

  return formattedCep;
};


export const removeSpecialCharacters = (value) => {
  return value.replace(/[^0-9]/g, '');
};