import React from "react";
import { Box, CircularProgress, Container, LinearProgress, Typography } from "@mui/material";

require("../pages/tokenRenewal");

const CenteredCircularProgress = ({ message, button, progress }) => {
  const saveUploads =
    message && button === "save" ? (
      <>
        <p>Realizando o upload dos documentos. Por favor, aguarde...</p>
      </>
    ) : null;

  const finalizeUploads =
    message && button === "finalize" ? (
      <>
        <p>Realizando o envio de documentos. Por favor, aguarde...</p>
      </>
    ) : null;

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Adjust this to the desired height
        zIndex: 99999,
        flexDirection: "column",
      }}
    >
      <CircularProgress style={{ color: "#e5007e" }}  />
      {saveUploads}
      {finalizeUploads}
      {progress &&  (
         <Box sx={{ width: '100%', maxWidth: 400, mt: 2 }}>
           <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            height: 10,
            borderRadius: 5,
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#e5007e", 
            },
            "& .MuiLinearProgress-root": {
              backgroundColor: "#f0f0f0", 
            }
          }}
        />
        
       </Box>
       
      )}
    </Container>
  );
};

export default CenteredCircularProgress;
